
import { useLoader } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import * as THREE from 'three'

export default function HeizkoerperGlb({size = [207, 73, 43]}) {
    const width = 207.0;
    const height = 72.8;
    const depth = 43.0;

    const gltf = useLoader(GLTFLoader, '/heizkoerperverkleidung_0.6.glb')
    const helper = new THREE.BoxHelper(gltf.nodes.heizkoerperverkleidung_1, 0xff0000);
    helper.update();
    helper.geometry.computeBoundingBox();
    console.log(helper.geometry.boundingBox.min);
    console.log(helper.geometry.boundingBox.max);

    return <primitive
        scale={[size[0] / width, size[1] / height, size[2] / depth]}
        object={gltf.scene}
    />
}
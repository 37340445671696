import React, {useRef, useState} from 'react';
import {Canvas, useFrame} from '@react-three/fiber'
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Container from '@mui/material/Container';
import MetricSlider from "./MetricSlider";
import './App.css';
import {CameraControls, Environment} from '@react-three/drei'
import HeizkoerperGlb from "./HeizkoerperGlb";

function App() {

  const [length, setLength] = React.useState(207);
  const [height, setHeight] = React.useState(73);
  const [depth, setDepth] = React.useState(43);

  return (
    <div className="App">
      <header className="App-header">
        <h2>3D Heizkörperverkleidung</h2>
        <Container maxWidth="lg">
          <p>
            <a style={{color: "white"}} href={"/heizkoerperverkleidung_0.6.usdz"}>Augmented Reality für Apple</a><br />
            Angezeigte Größe: {length}cm x {height}cm x {depth}cm
          </p>
        </Container>
        <Container maxWidth="lg">
          <MetricSlider value={length} onChange={setLength} name="Länge" min={70} max={350} />
          <MetricSlider value={height} onChange={setHeight} name="Höhe" min={60} max={100} />
          <MetricSlider value={depth} onChange={setDepth} name="Tiefe" min={20} max={60} />
        </Container>
        <Container maxWidth="lg" style={{height: "400px"}}>
          <Canvas frameloop="demand">
            <CameraControls minPolarAngle={0} maxPolarAngle={Math.PI / 1.6}/>
            <ambientLight intensity={Math.PI / 2}/>
            <spotLight position={[18, 26, 10]} penumbra={1} decay={0} intensity={20}/>
            <pointLight position={[0, 26, 11]} decay={0} intensity={20}/>
            <HeizkoerperGlb size={[length, height, depth]} />
            {/*<Environment preset="lobby" background />*/}
            <gridHelper/>
          </Canvas>
        </Container>
      </header>
    </div>
  );
}

export default App;

import * as React from "react";
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';


export default function MetricSlider({value, onChange, name, unit = "cm", min = 0, max = 250, }) {
    const handleChange = (event, newValue) => {
        onChange(newValue);
    };

  return <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
      <span style={{whiteSpace: "nowrap"}}>{name}: {min}{unit}</span>
      <Slider
          aria-label="Length"
          value={value}
          min={min}
          max={max}
          onChange={handleChange}
          valueLabelDisplay="auto"
      />
      <span>{max}{unit}</span>
  </Stack>
}